




















import BaseDialog from '@/components/BaseDialog.vue';

export default {
  name: 'CopyStakeUserBlacklistConfirmation',
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    isRemoveAll: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isRemoveUsers(): boolean {
      return this.isRemove || this.isRemoveAll;
    },

    title(): string {
      if (this.isRemove) {
        return 'Remove from CopyStake Blacklist';
      }

      if (this.isRemoveAll) {
        return 'Remove All';
      }

      return 'Add to CopyStake Blacklist';
    },

    description(): string {
      if (this.isRemove) {
        return `Do you want to remove this user from CopyStake blacklist? User will use CopyStake without restriction.`;
      }

      if (this.isRemoveAll) {
        return 'Do you want to remove all user from CopyStake blacklist? This users will use CopyStake without restriction.';
      }

      return `Do you want to add this user to CopyStake blacklist? User won’t use CopyStake to replicate bets.`;
    }
  }
};
