import { render, staticRenderFns } from "./UserManagementBalance.vue?vue&type=template&id=a2c5ec4a&scoped=true&lang=pug"
import script from "./UserManagementBalance.vue?vue&type=script&lang=ts"
export * from "./UserManagementBalance.vue?vue&type=script&lang=ts"
import style0 from "./UserManagementBalance.vue?vue&type=style&index=0&id=a2c5ec4a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c5ec4a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VDivider,VList,VRow,VSkeletonLoader})
