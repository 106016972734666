
















































































import { EOperationLimitCoefficientType } from '@/api/schema';
import * as vr from '@/helpers/validation';
import { mapGetters, mapState } from 'vuex';
import type { PropType } from 'vue';

interface ITabData {
  tabName: string;
  type: EOperationLimitCoefficientType;
  firstInfoRow: string;
  secondInfoRow: string;
  visible: boolean;
}

export interface ILimitCoefficientEmitData {
  type: EOperationLimitCoefficientType;
  value: number;
}

export interface ICoefficientData {
  header?: string;
  disclaimer?: string;
  stakingLimit: number;
  tokenPreSaleCoefficient: number | null;
}

export default {
  name: 'LimitCoefficientDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<ICoefficientData>,
      default: (): ICoefficientData => {
        return {} as ICoefficientData;
      }
    }
  },
  data(): any {
    return {
      EOperationLimitCoefficientType,
      formIsValid: false,
      stakingLimit: null,
      tokenPreSaleCoefficient: null,
      tab: 0
    };
  },
  computed: {
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapState('app', ['isSuperAdmin']),
    tabsOptions(): ITabData[] {
      return [
        {
          tabName: 'Staking limit',
          type: EOperationLimitCoefficientType.STAKING_LIMIT,
          firstInfoRow:
            'Set Staking limit coefficient to define max staking amount for limited staking programs.',
          secondInfoRow:
            ' Staking Amount = Staking limit coefficient * Play to Earn',
          visible: true
        },
        {
          tabName: 'Token Purchase',
          type: EOperationLimitCoefficientType.TOKEN_PRE_SALE,
          firstInfoRow:
            'Set Token Purchase coefficient do define Max deposit.',
          secondInfoRow:
            'Max Deposit = Tokens Purchase coefficient * play to earn + Withdrawals - Deposited funds',
          visible: this.isVisibleTokenPurchaseTab
        }
      ].filter((item: ITabData): boolean => {
        return !!item.visible;
      });
    },
    isVisibleTokenPurchaseTab(): boolean {
      return this.data?.tokenPreSaleCoefficient && !this.isListed;
    },
    currentTab(): ITabData {
      return this.tabsOptions[this.tab];
    },
    operationType(): string {
      return this.currentTab.type;
    },
    disableSubmit(): boolean {
      return (
        (this.operationType === EOperationLimitCoefficientType.STAKING_LIMIT
          ? !this.stakingLimit
          : !this.tokenPreSaleCoefficient) || !this.formIsValid
      );
    },
    rules(): any {
      return {
        stakingLimit: [
          vr.required,
          vr.integer,
          vr.positiveNumber,
          (v) => vr.gte(v, 1000, 'Min 1 000 - Max 10 000 000'),
          (v) => vr.lte(v, 10000000, 'Min 1 000 - Max 10 000 000')
        ],
        tokenPreSaleCoefficient: [
          vr.required,
          vr.integer,
          vr.positiveNumber,
          (v) => vr.gte(v, 1, 'Min 1 - Max 5 000'),
          (v) => vr.lte(v, 5000, 'Min 1 - Max 5 000')
        ]
      };
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    header(): string {
      return this.data?.header || 'Set Coefficients';
    },
    disclaimer(): string {
      return this.data?.disclaimer || '';
    }
  },
  watch: {
    value(): void {
      this.updateValue();
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formIsValid) {
          const data: ILimitCoefficientEmitData = {
            type: this.operationType,
            value:
              this.operationType ===
              EOperationLimitCoefficientType.STAKING_LIMIT
                ? this.stakingLimit
                : this.tokenPreSaleCoefficient
          };

          this.$emit('submit', data);
        }
      });
    },
    updateValue(): void {
      this.stakingLimit = this.data?.stakingLimit || null;
      this.tokenPreSaleCoefficient =
        this.data?.tokenPreSaleCoefficient || null;
    }
  }
};
