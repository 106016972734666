













































































import { mapGetters, mapState } from 'vuex';
import {
  getUserBalances,
  transferFromUser,
  transferToUser,
  setCoefficient,
  getUserTransactionLimits,
  updateUserTransactionLimits,
  getUserDetails,
  syncUserBalance,
  setTokenPreSale,
  setProfitActivity
} from '@/api/ClientInfo';
import {
  getCopyStakeUsersBlacklist,
  addCopyStakeUserBlacklist,
  removeCopyStakeUserBlacklist
} from '@/api/CopyStake';
import UserManagementTabsView from '@/views/DashboardViews/user-management/UserManagementTabsView.vue';
import UserManagementDetails from './UserManagementDetails.vue';
import UserManagementBalance from './UserManagementBalance.vue';
import UserManagementBalanceDialog from './UserManagementBalanceDialog.vue';
import UserManagementLimitsDialog from './UserManagementLimitsDialog.vue';
import CopyStakeUserBlacklistConfirmation from '@/views/CopyStake/CopyStakeUserBlacklist/CopyStakeUserBlacklistConfirmation.vue';
import LimitCoefficientDialog, {
  ICoefficientData,
  ILimitCoefficientEmitData
} from '@/components/LimitCoefficientDialog.vue';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type {
  UserBalancesData,
  UserTransactionItem,
  UserTransferBalanceData,
  UserDetails,
  UserTransactionLimit,
  FeatureFlagItem
} from '@/api/schema';
import {
  EOperationLimitCoefficientType,
  PaymentMethodTypeEnum,
  EProfitActivity
} from '@/api/schema';
import ConfirmationPromiseDialog from '@/components/ConfirmationPromiseDialog.vue';
export const changeBalanceOperations = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw'
};

export default {
  name: 'UserManagement',
  components: {
    ConfirmationPromiseDialog,
    CopyStakeUserBlacklistConfirmation,
    UserManagementTabsView,
    UserManagementDetails,
    UserManagementBalance,
    UserManagementBalanceDialog,
    LimitCoefficientDialog,
    UserManagementLimitsDialog
  },
  data(): any {
    return {
      transactionsReasons: [],
      changeBalanceDialog: false,
      coefficientDialog: false,
      limitsDialog: false,
      blacklistDialog: false,
      playToEarnDialog: false,
      loading: {
        details: false,
        balances: true,
        transactionLimits: true,
        coefficientData: false,
        blacklisted: true
      },
      userData: {
        details: {
          operatorUserId: null,
          createdAt: '',
          tokenPurchaseCompanyMember: false,
          p2eActive: false,
          h2eActive: false
        } as UserDetails,
        balances: {} as UserBalancesData,
        transactionLimits: {
          withdrawLimit: 0,
          depositLimit: 0,
          preSaleDepositLimit: 0
        },
        blacklisted: false
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('FeatureFlags', ['features']),
    userId(): number {
      return +this.$route.params.userId;
    },
    operatorUserId(): string {
      return this.userData.details.operatorUserId;
    },
    coefficientData(): ICoefficientData {
      return {
        stakingLimit: this.userData.balances.limitedProgramCoefficient,
        tokenPreSaleCoefficient: this.userData.balances.preSaleCoefficient
      };
    },
    transactions(): UserTransactionItem[] {
      const page = this.transactionsPage;
      const size = this.transactionsSize;

      return this.userData.transactionsData.transactions.slice(
        page * size,
        (page + 1) * size
      );
    },

    isEnabledCopyStake(): boolean {
      const copyStakeFeatureFlag = this.features.find(
        (feature: FeatureFlagItem) => feature.name === 'live_streamer'
      );

      if (!copyStakeFeatureFlag) return false;

      const userIds = copyStakeFeatureFlag.operatorUserIds;

      if (copyStakeFeatureFlag.enabled && userIds.length) {
        return userIds.includes(this.operatorUserId);
      }

      return copyStakeFeatureFlag.enabled;
    },

    watchGroupCopyStake(): boolean[] {
      return [this.isEnabledCopyStake, this.operatorUserId];
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null, oldId: number | null): void {
        if (newId && !oldId) {
          this.getBalances(this.userId);
          this.getTransactionLimits(this.userId);
          this.getUserDetails(this.userId);
        }
        if (newId && oldId) {
          if (this.isSuperAdmin) return this.backHandler();
        }
      },
      immediate: true
    },
    watchGroupCopyStake: {
      handler(): void {
        if (this.isEnabledCopyStake) this.getUserBlacklistStatus();
      },
      immediate: true
    }
  },
  methods: {
    refreshBalanceAction(): void {
      this.loading.balances = true;
      syncUserBalance(this.userId)
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally(() => {
          this.loading.balances = false;
        });
    },
    backHandler(): void {
      this.$router.push({
        name: this.isSuperAdmin ? 'account-operator-token-users' : 'users'
      });
    },
    getBalances(userId: number): void {
      getUserBalances(userId).then((data: UserBalancesData): void => {
        this.setLoadingData(data, 'balances');
      });
    },
    getTransactionLimits(userId: number): void {
      getUserTransactionLimits(userId).then(
        (data: UserTransactionLimit): void => {
          this.setLoadingData(data, 'transactionLimits');
        }
      );
    },
    getUserDetails(userId: number): void {
      getUserDetails(userId).then((data: UserDetails): void => {
        this.setLoadingData(data, 'details');
      });
    },
    setLoadingData(data: UserDetails | any, key: string): void {
      this.loading[key] = false;
      this.userData[key] = data;
    },
    openDialog(): void {
      this.changeBalanceDialog = true;
    },
    closeDialog(): void {
      this.changeBalanceDialog = false;
    },
    openCoefficientDialog(): void {
      this.coefficientDialog = true;
    },
    closeCoefficientDialog(): void {
      this.coefficientDialog = false;
    },
    openLimitsDialog(): void {
      this.limitsDialog = true;
    },
    closeLimitsDialog(): void {
      this.limitsDialog = false;
    },
    openBlacklistDialog(): void {
      this.blacklistDialog = true;
    },
    closeBlacklistDialog(): void {
      this.blacklistDialog = false;
    },
    getBalanceChangeHandler(operationType: string): unknown {
      switch (operationType) {
        case changeBalanceOperations.WITHDRAW:
          return transferFromUser;
        case changeBalanceOperations.DEPOSIT:
          return transferToUser;
        default:
          return null;
      }
    },
    changeBalanceHandler(
      operationType: string,
      data: UserTransferBalanceData
    ): void {
      const handler = this.getBalanceChangeHandler(operationType);

      this.loading.balances = true;
      handler(this.userId, data)
        .then((): void => {
          const toastMessage = (): string => {
            switch (operationType) {
              case changeBalanceOperations.DEPOSIT:
                return `${data.amount} ${this.tokenSymbol} has been added to balance`;
              case changeBalanceOperations.WITHDRAW:
                return `${data.amount} ${this.tokenSymbol} has been withdraw from balance`;
              default:
                return 'Balance updated';
            }
          };
          this.closeDialog();
          this.getBalances(this.userId);
          this.$toast.success(toastMessage());
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
          this.loading.balances = false;
        });
    },
    coefficientHandler(data: ILimitCoefficientEmitData): void {
      this.loading.coefficientData = true;
      const configMap = {
        [EOperationLimitCoefficientType.STAKING_LIMIT]: {
          handler: setCoefficient,
          toastMessage: 'Staking limit coefficient has been updated'
        },
        [EOperationLimitCoefficientType.TOKEN_PRE_SALE]: {
          handler: setTokenPreSale,
          toastMessage: 'Token Purchase coefficient has been updated'
        }
      };

      const config = configMap[data.type];
      if (!config) return;

      config
        .handler(this.userId, data.value)
        .then((): void => {
          this.closeCoefficientDialog();
          this.getBalances(this.userId);
          this.$toast.success(config.toastMessage);
        })
        .catch((err: AxiosError): void => {
          this.loading.coefficientData = false;
          errorToastMessage(err);
        });
    },

    limitsHandler(type: PaymentMethodTypeEnum, value: number): void {
      this.loading.transactionLimits = true;

      const isTokenPurchaseDeposit =
        type === PaymentMethodTypeEnum.TOKEN_PURCHASE_DEPOSIT;

      const request = isTokenPurchaseDeposit
        ? setTokenPreSale(this.userId, null, value)
        : updateUserTransactionLimits({
            userId: this.userId,
            type,
            minimumTransactionAmount: null,
            maximumTransactionAmount: value
          });

      request
        .then((): void => {
          this.closeLimitsDialog();

          this.getTransactionLimits(this.userId);

          if (isTokenPurchaseDeposit) this.getBalances(this.userId);

          this.$toast.success(`Operator Limits has been updated`);
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally(() => {
          this.loading.transactionLimits = false;
        });
    },
    getUserBlacklistStatus(): void {
      if (!this.operatorUserId) return;

      this.loading.blacklisted = true;

      getCopyStakeUsersBlacklist({
        operatorUserId: this.operatorUserId,
        page: 0,
        size: 1
      })
        .then((response) => (this.userData.blacklisted = !response.empty))
        .finally(() => (this.loading.blacklisted = false));
    },
    setCopyStakeBlacklistHandler(): void {
      const { blacklisted } = this.userData;

      const request = blacklisted
        ? removeCopyStakeUserBlacklist
        : addCopyStakeUserBlacklist;

      const eventName = blacklisted ? 'removed from' : 'added to';

      request([this.operatorUserId])
        .then((response) => {
          if (response.length) {
            this.$toast.error(
              `User was failed ${eventName} the CopyStake blacklist`
            );
          } else {
            this.$toast.success(
              `User was successfully ${eventName} the CopyStake blacklist`
            );
          }
        })
        .finally(() => {
          this.getUserBlacklistStatus();
          this.closeBlacklistDialog();
        });
    },

    setProfitActivity(type: EProfitActivity, value: boolean): void {
      if (type === EProfitActivity.P2E) {
        this.userData.details.p2eActive = value;
      }

      if (type === EProfitActivity.H2E) {
        this.userData.details.h2eActive = value;
      }
    },

    async changeProfitActivity(type: string, value: boolean): Promise<void> {
      const isRemove = value !== true;
      const action = isRemove ? 'Disable' : 'Enable';
      const typeNames = {
        [EProfitActivity.P2E]: 'Play to Earn',
        [EProfitActivity.H2E]: 'Hold to Earn'
      };
      const typeName = typeNames[type];

      const confirm = await this.$refs.confirm.openDialog({
        title: `${action} ${typeName}`,
        text: isRemove
          ? `Do you want to add this user to ${typeName} blacklist? This user will not receive a reward for any bets`
          : `Do you want to remove this user from ${typeName} blacklist? This user start to receive a reward for any bets`,
        controls: {
          submit: {
            color: isRemove ? 'error' : 'primary',
            text: action
          }
        }
      });

      if (!confirm) return;

      this.setProfitActivity(type, value);

      setProfitActivity(this.userId, {
        p2eActive: this.userData.details.p2eActive,
        h2eActive: this.userData.details.h2eActive
      }).then(() => {
        if (!isRemove) {
          this.$toast.success(
            `User successfully removed from the ${typeName} blacklist`
          );
        } else {
          this.$toast.success(
            `User successfully added to the ${typeName} blacklist`
          );
        }
      });
    }
  }
};
