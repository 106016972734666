












export default {
  name: 'ExternalLink',
  props: {
    icon: {
      type: String,
      default: 'mdi-open-in-new'
    },
    dense: {
      type: Boolean,
      default: (): boolean => false
    },
    text: {
      type: String,
      default: ''
    }
  }
};
