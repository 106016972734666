import { render, staticRenderFns } from "./ExternalLink.vue?vue&type=template&id=44d6b392&scoped=true&lang=pug"
import script from "./ExternalLink.vue?vue&type=script&lang=ts"
export * from "./ExternalLink.vue?vue&type=script&lang=ts"
import style0 from "./ExternalLink.vue?vue&type=style&index=0&id=44d6b392&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d6b392",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
