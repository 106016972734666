
































import VTabsHash from '@/components/VTabsHash.vue';
import { PropType } from 'vue';
import type { UserDetails, TTabsItem } from '@/api/schema';

export enum EUserManagementTabsKeys {
  TRANSACTIONS_SUMMARY = 'transactions-summary',
  TRANSACTIONS = 'transactions',
  TOTAL_GGR = 'total-ggr',
  USER_PROMO_CAMPAIGN = 'user-promo-campaign',
  MANUAL_TRANSFERS = 'manual-transfers'
}

export default {
  name: 'UserManagementTabsView',
  components: {
    VTabsHash,
    UserManagementTransactionSummaryTab: (): any =>
      import(
        '@/views/DashboardViews/user-management/UserManagementTransactionSummaryTab.vue'
      ),
    UserManagementTotalGgrTab: (): any =>
      import(
        '@/views/DashboardViews/user-management/UserManagementTotalGgrTab.vue'
      ),
    UserManagementPromoCampaignsTab: (): any =>
      import(
        '@/views/DashboardViews/user-management/UserManagementPromoCampaignsTab.vue'
      ),
    UserManagementTransactionsTab: (): any =>
      import(
        '@/views/DashboardViews/user-management/UserManagementTransactionsTab.vue'
      ),
    UserManagementManualTransfersTab: (): any =>
      import(
        '@/views/DashboardViews/user-management/UserManagementManualTransfersTab.vue'
      )
  },
  data(): any {
    return {
      currentTab: 0
    };
  },
  props: {
    userDetails: {
      type: Object as PropType<UserDetails>,
      required: true
    }
  },
  computed: {
    operatorUserId(): string {
      return this.userDetails?.operatorUserId || null;
    },
    operatorUserCreatedAt(): string {
      return this.userDetails?.createdAt || null;
    },
    tabs(): TTabsItem[] {
      const arrTabs = [
        {
          id: EUserManagementTabsKeys.TRANSACTIONS_SUMMARY,
          text: 'Transactions Summary',
          component: 'user-management-transaction-summary-tab',
          visible: true
        },
        {
          id: EUserManagementTabsKeys.TRANSACTIONS,
          text: 'Transactions',
          component: 'user-management-transactions-tab',
          visible: true
        },
        {
          id: EUserManagementTabsKeys.TOTAL_GGR,
          text: 'Total GGR',
          component: 'user-management-total-ggr-tab',
          visible: true
        },
        {
          id: EUserManagementTabsKeys.USER_PROMO_CAMPAIGN,
          text: 'User Promo Campaign',
          component: 'user-management-promo-campaigns-tab',
          visible: true
        },
        {
          id: EUserManagementTabsKeys.MANUAL_TRANSFERS,
          text: 'Manual Transfers',
          component: 'user-management-manual-transfers-tab',
          visible: true
        }
      ];

      return arrTabs.filter((tab) => tab.visible);
    },
    userId(): number {
      return +this.$route.params.userId;
    }
  },
  methods: {
    handlerChangeTab(tabKey: EUserManagementTabsKeys): void {
      this.currentTab = this.tabs.findIndex(
        (tab: TTabsItem) => tab.id === tabKey
      );
    }
  }
};
