











































import { mapActions, mapGetters, mapState } from 'vuex';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { getWidgetUrl } from '@/api/ClientInfo';
import type { PropType } from 'vue';
import type { UserDetails } from '@/api/schema';

interface DataMap {
  name: string;
  field: string;
  visible?: boolean;
  copy?: boolean;
}

export default {
  name: 'UserManagementDetails',
  props: {
    details: {
      type: Object as PropType<UserDetails>,
      default: (): UserDetails => {
        return {} as UserDetails;
      },
      required: true
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => true
    }
  },
  data(): { widgetUrl: string } {
    return {
      widgetUrl: null
    };
  },
  watch: {
    apiKeyRequestNeeded: {
      handler: 'getApiKey',
      immediate: true
    },
    urlRequestNeeded: {
      handler: 'getWidgetUrl',
      immediate: true
    }
  },
  computed: {
    ...mapGetters('Onboarding', ['isMobile', 'operatorId']),
    ...mapState('Onboarding', ['apiKey']),
    ...mapState('ClientsInfo', ['clientsInfo']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('SuperAdmin', ['currentAccount']),
    dataMap(): DataMap[] {
      return [
        {
          name: 'Registration Date',
          field: 'createdAt'
        },
        {
          name: 'ID',
          field: 'id',
          visible: !this.isMobile,
          copy: true
        },
        {
          name: 'Operator User ID',
          field: 'operatorUserId',
          copy: true
        },
        {
          name: 'Wallet Address',
          field: 'walletHash',
          visible: !this.isMobile,
          copy: true
        },
        {
          name: 'KYC',
          field: 'kyc'
        }
      ].filter((item: DataMap): boolean => {
        return item.visible ?? true;
      });
    },
    apiKeyRequestNeeded(): boolean {
      return !!(
        this.$role.can.update('users') &&
        this.clientsInfo?.widgetEnabled &&
        this.operatorId &&
        !this.apiKey
      );
    },
    urlRequestNeeded(): boolean {
      return (
        this.isSuperAdmin &&
        !this.currentAccount.copyStakeAccount &&
        this.apiKey &&
        this.details?.operatorUserId &&
        !this.widgetUrl
      );
    }
  },
  methods: {
    ...mapActions('Onboarding', ['getApiKeys']),
    copyToClipBoard,
    getApiKey(v: boolean): void {
      if (!v) return;

      this.getApiKeys();
    },
    async getWidgetUrl(v: boolean): Promise<void> {
      if (!v) return;

      getWidgetUrl(this.details.operatorUserId, this.apiKey)
        .then(({ url }) => {
          this.widgetUrl = url;
        })
        .catch(() => {
          this.widgetUrl = null;
        });
    }
  }
};
