

















































































































import { mapGetters, mapState } from 'vuex';
import type { PropType } from 'vue';
import {
  ProgramDialogData,
  UserBalancesData,
  ConfirmationDialogData,
  PaymentMethodTypeEnum
} from '@/api/schema';
import * as vr from '@/helpers/validation';
import { roundNumber } from '@/helpers/roundNumber';
import ExternalLink from '@/components/links/ExternalLink.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';

interface ITabItem {
  text: string;
  value: PaymentMethodTypeEnum;
  hide?: boolean;
}

function toNullableNum(value: string | null) {
  return value === null || value === '' ? null : +value;
}

export default {
  name: 'UserManagementLimitsDialog',
  components: {
    ExternalLink,
    ConfirmationDialog
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    limitData: {
      type: Object as PropType<ProgramDialogData>,
      default: (): ProgramDialogData => {
        return {} as ProgramDialogData;
      }
    },
    userBalance: {
      type: Object as PropType<UserBalancesData>,
      default: {} as UserBalancesData
    },
    tokenPurchaseCompanyMember: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      PaymentMethodTypeEnum,
      formIsValid: true,
      confirmationDialog: false,
      tab: 0,
      payload: {
        withdrawLimit: null,
        depositLimit: null,
        preSaleDepositLimit: 0
      }
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('ClientsInfo', ['clientsInfo']),
    ...mapGetters('ClientsInfo', ['isListed']),

    typeOptions(): ITabItem[] {
      return [
        {
          text: 'Withdraw',
          value: PaymentMethodTypeEnum.OPERATOR_WITHDRAW
        },
        {
          text: 'Deposit',
          value: PaymentMethodTypeEnum.OPERATOR_DEPOSIT
        },
        {
          text: 'Token Purchase Deposit',
          value: PaymentMethodTypeEnum.TOKEN_PURCHASE_DEPOSIT,
          hide: !this.tokenPurchaseCompanyMember
        }
      ].filter((tab) => !tab.hide);
    },

    operationType(): ITabItem {
      return this.typeOptions.find(
        (type: ITabItem) => type.value === this.tab
      ).value;
    },

    rules(): any {
      return {
        limit: [
          (v) => {
            if (v === '' || v === null) {
              return true;
            }

            let result = vr.integer(v);

            if (result !== true) {
              return result;
            }

            return vr.positiveNumber(v);
          }
        ]
      };
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    confirmationDialogData(): ConfirmationDialogData {
      const currentDepositLimit = this.formatNumber(this.depositLimit);
      const newDepositLimit = this.formatNumber(
        this.payload.preSaleDepositLimit || this.limitData.depositLimit
      );

      return {
        header: 'Warning!',
        disclaimer: `Current user was added to Token Purchase campaign (max transaction limit ${currentDepositLimit}). Are you sure to set limit ${newDepositLimit}`
      };
    },

    depositLimit(): number {
      return (
        this.limitData.preSaleDepositLimit || this.limitData.depositLimit
      );
    },

    showConfirmation(): boolean {
      return (
        !this.confirmationDialog &&
        this.payload.preSaleDepositLimit !== this.depositLimit
      );
    }
  },
  watch: {
    value: {
      handler(newValue: boolean): void {
        if (newValue) this.resetFromData();
      },
      immediate: true
    }
  },
  methods: {
    roundNumber,

    resetFromData(): void {
      this.payload = Object.assign({}, this.limitData);
      this.$refs.form?.resetValidation();
      this.tab = 0;
    },

    formatNumber(value: number): string {
      return this.$options.filters.numeralSpaces(value, '0,0[00]');
    },

    submitHandler(): void {
      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          if (
            this.operationType === PaymentMethodTypeEnum.OPERATOR_WITHDRAW
          ) {
            this.$emit(
              'submit',
              this.operationType,
              toNullableNum(this.payload.withdrawLimit)
            );
          } else if (
            this.operationType === PaymentMethodTypeEnum.OPERATOR_DEPOSIT
          ) {
            this.$emit(
              'submit',
              this.operationType,
              toNullableNum(this.payload.depositLimit)
            );
          } else if (
            this.operationType ===
            PaymentMethodTypeEnum.TOKEN_PURCHASE_DEPOSIT
          ) {
            if (this.showConfirmation) {
              this.confirmationDialog = true;

              return;
            }
            this.$emit(
              'submit',
              this.operationType,
              toNullableNum(this.payload.preSaleDepositLimit || 0)
            );
            this.confirmationDialog = false;
          }
        }
      });
    }
  }
};
