


























































































import { mapState } from 'vuex';
import { changeBalanceOperations } from './UserManagement.vue';
import * as vr from '@/helpers/validation';
import { uuid } from 'uuidv4';
import type { PropType } from 'vue';

const reasons = {
  affiliatePayment: 'Affiliate payment',
  striamerPayment: 'Streamer payment',
  manualDeposit: 'Manual deposit',
  manualWithdraw: 'Manual Withdraw',
  other: 'Other'
};

const defaultFormData = { message: '', reason: null, amount: null };

export default {
  name: 'UserManagementBalanceDialog',

  data(): any {
    return {
      formValid: true,
      tab: 0,
      formData: {
        ...defaultFormData
      },
      depositReasons: [
        [reasons.affiliatePayment, reasons.manualDeposit],
        [reasons.striamerPayment, reasons.other]
      ],
      withdrawReasons: [[reasons.manualWithdraw], [reasons.other]],
      amountRules: [
        (v) => vr.required(v, 'Amount'),
        (v) => vr.validNumber(v, 'Amount should be a valid number')
      ],
      reasonsRules: [(v) => vr.required(v, 'Reason')],
      operationRules: [(v) => vr.required(v, 'Operation')],
      operationsOptions: [
        {
          text: 'Add Tokens',
          value: changeBalanceOperations.DEPOSIT
        },
        {
          text: 'Remove Tokens',
          value: changeBalanceOperations.WITHDRAW
        }
      ]
    };
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    operations(): string[] {
      return this.operationsOptions.map((item) => item.text);
    },
    operationType(): string[] {
      return this.operationsOptions[this.tab].value;
    },
    disableSubmit(): boolean {
      const { reason, amount, message } = this.formData;
      if (this.loading) return this.loading;
      if (!reason || !amount) return true;
      if (this.isMessageRequired && !message) return true;
      return false;
    },
    messageRules(): unknown[] {
      return [
        ...(this.isMessageRequired ? [(v) => vr.required(v, 'Message')] : []),
        ...(this.formData.message ? [(v) => vr.maxLength(v, 400)] : [])
      ];
    },
    reasons(): string[] {
      if (this.operationType === changeBalanceOperations.DEPOSIT)
        return this.depositReasons;
      if (this.operationType === changeBalanceOperations.WITHDRAW)
        return this.withdrawReasons;
      return [];
    },
    isMessageRequired(): boolean {
      return this.formData.reason === reasons.other;
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value(newValue: boolean): void {
      if (!newValue) this.resetFromData();
    },
    operation(): void {
      this.$refs.form.resetValidation();
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formValid) {
          this.$emit('submit', this.operationType, {
            actionId: uuid(),
            ...this.formData
          });
        }
      });
    },
    resetFromData(): void {
      const form = this.$refs.form;
      form?.reset();
      this.formData = { ...defaultFormData };
      this.operation = changeBalanceOperations.DEPOSIT;
    }
  }
};
